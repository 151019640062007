<template>
  <div class="scanPage">
    <div class="wrapper">
      <div class="canvasWrapper">
        <canvas
          id="canvas"
          ref="canvas"
          :style="{
            width: clientWidth + 'px',
            height: clientHeight * 0.7 + 'px',
          }"
          >您的浏览器不支持H5 ,请更换或升级!</canvas
        >
        <div class="photoImage" v-if="showPhotoImage">
          <img :src="imageSrc" alt="" />
        </div>
      </div>
      <div class="otherContent">
        <p>将二维码/条码放入扫描区域，即可自动扫描</p>
        <div class="tips">
          <i class="iconfont icontishi"></i>
          <p>如自动扫码无效，请点击下方按钮拍照识别！</p>
        </div>
        <div class="photograph">
          <!-- <van-button @click="back">返回</van-button> -->
          <van-uploader :before-read="beforeRead" :after-read="afterRead">
            <van-button icon="photograph" round type="info"
              >拍照识别</van-button
            >
          </van-uploader>
        </div>
      </div>
    </div>
    <video
      id="video"
      ref="video"
      autoplay="true"
      muted
      :style="{ width: clientWidth + 'px', height: clientHeight * 0.7 + 'px' }"
    ></video>
  </div>
</template>

<script>
import jsQR from "jsqr";
import Jimp from "jimp";
import imageCompression from "browser-image-compression";
export default {
  data() {
    return {
      canvas: undefined,
      video: undefined,
      timer: null,
      timer2: null,
      media: null,
      context: undefined,
      clientWidth: null,
      clientHeight: null,
      showPhotoImage: false,
      imageSrc: "",
    };
  },
  beforeDestroy(){
    this.close();
  },
  mounted() {
    this.initCamera();
  },
  methods: {
    initCamera: function () {
      this.canvas = this.$refs.canvas;
      this.video = this.$refs.video;
      this.clientWidth = document.documentElement.clientWidth;
      this.clientHeight = document.documentElement.clientHeight;
      this.context = this.canvas.getContext("2d");
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.mediaDevices.getUserMedia;
      this.open();
    },
    open: function () {
      const that = this;
      if (!this.timer) {
        navigator.getUserMedia(
          {
            audio: false,
            video: {
              width: { min: 1024, ideal: 1280, max: 1920 },
              height: { min: 776, ideal: 720, max: 1080 },
              frameRate: { ideal: 10, max: 15 },
              facingMode: "environment",
            },
          },
          function (stream) {
            that.video.srcObject = stream;
            that.video.onloadedmetadata = function () {
              that.video.play();
            };
          },
          function (err) {
            alert(err); //弹窗报错
          }
        );
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
      //将画面绘制到canvas中
      this.timer = setInterval(function () {
        that.context.drawImage(
          that.video,
          0,
          0,
          that.clientWidth,
          that.clientHeight * 0.7
        );
      }, 16.6);
      if (this.timer2) {
        clearInterval(this.timer2);
      }
      this.timer2 = setInterval(() => {
        this.screenshot();
      }, 50);
    },
    close: function () {
      //关闭截屏
      clearInterval(this.timer);
      clearInterval(this.timer2);
    },

    //截屏
    screenshot: function () {
      //获得当前帧的图像并拿到数据
      let image = this.canvas.toDataURL(); //返回一个包含图片展示的 数据URL（base64）
      this.IdentifyQRCode(image, "auto");
    },

    //识别二维码
    IdentifyQRCode: function (image, type) {
      //获取图片宽高，图片转格式
      Jimp.read(image).then((blockimg) => {
        let width = blockimg.bitmap.width,
          height = blockimg.bitmap.height,
          imgData = blockimg.bitmap.data;
          //如果是拍摄的需要裁剪
          if(type==='manual'){
            blockimg.crop(width*0.15,height*0.15,width*0.7,height*0.7);
            let newWidth = blockimg.bitmap.width,
                newHeight = blockimg.bitmap.height,
                newImgData = blockimg.bitmap.data;
            this.startRecognizingQRCode(newImgData,newWidth,newHeight,type)
            blockimg.getBase64(Jimp.MIME_PNG,(err,src)=>{
             if(err){
               console.log(err)
             }else{
              this.imageSrc = src;
              this.showPhotoImage = true;
             }
            })
          }else{
            this.startRecognizingQRCode(imgData,width,height,type)
          }
      });
    },

    //开始识别二维码
    startRecognizingQRCode:function(imgData,width,height,type){
      let code = jsQR(imgData, width, height);
      if (code) {
        this.$toast.success("扫描成功");
        if (type === "auto") {
          clearInterval(this.timer2);
        }
        localStorage.setItem("ScanedUrl",code.data);
        setTimeout(()=>{
          this.$router.go(-1)
        },1100)
      } else {
        if (type === "manual") {
          this.$toast.fail("识别失败，请重新拍照！");
        }
        // console.log("识别失败")
      }
    },

    back:function(){
      let url = 'https://panelscan-test.getgeekfun.cn/#/model?id=Panel_88911_26e943c6-39ac-4647-b08b-25131c3d651d'
      localStorage.setItem("ScanedUrl",url);
      this.$router.go(-1)
    },

    beforeRead: function () {
      clearInterval(this.timer); //清除drawImage
      clearInterval(this.timer2); //清除截屏
      return true;
    },

    afterRead: async function (data) {
      const imageFile = data.file;
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: this.clientWidth,
        useWebWorker: true,
      };
      try {
        this.$toast.loading({
          message:"识别中...",
          duration: 0
        });
        const blob = await imageCompression(imageFile, options); //压缩图片，返回blob格式
        let reader = new FileReader();
        reader.readAsDataURL(blob);//blob流转换为base64
        reader.onload = (e) => {
          let newImg = new Image();
          newImg.src = e.target.result;
          newImg.onload = () => {
            // this.imageSrc = newImg.src;
            // this.showPhotoImage = true;
            this.IdentifyQRCode(newImg.src, "manual");
          };
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang='less' scoped>
.scanPage {
  color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #231f1e;
  overflow: hidden;
  z-index: 10;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .canvasWrapper {
      position: relative;
      overflow: hidden;
      border-bottom: 1px solid #969696;
      .photoImage {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .otherContent {
      height: 30%;
      overflow: hidden;
      padding: 40px 0;
      font-size: 34px;
      box-sizing: border-box;
      p {
        text-align: center;
      }
      .tips {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        font-size: 26px;
        i {
          color: #ff5722;
          font-size: 36px;
          margin-right: 10px;
        }
      }
      .photograph {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
  #canvas {
    width: 100%;
    height: 70%;
  }
  #video {
    width: 100%;
    height: 70%;
    display: none;
    background-color: #ccc;
  }
}
</style>
